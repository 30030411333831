<template>
    <div id="user-box" :class="[$mq, 'overlay']" @click.self="closeBox">
        <div class="menu">
            <!-- USER INFO -->
            <div class="user-info">
                <employee class="selected transparent no-click" :class="{ 'employee-small': false }" :employee="user" :rol="role"></employee>
            </div>
            <!-- USER ACTIONS -->
            <div class="user-actions hide-scrollbar">
                <div class="action profile" @click="userConfig()">
                    <div class="icon"></div>
                    <div class="text">Ver perfil</div>
                </div>
                <div class="action logout" @click="userLogout">
                    <div class="icon"></div>
                    <div class="text">Cerrar sesión</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import employee from '@/components/domain/login/employee'

export default {
    components: { employee },
    data() {
        return {}
    },
    computed: {
        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },

        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.emp_id)

            if (employee) {
                return employee
            }
        },

        role() {
            if (this.user) {
                if (this.user.rol) {
                    return this.$store.getters['employee/getRol'](this.user.rol)
                }
            }
        },
        home() {
            return this.$store.getters['getHome'] ? this.$store.getters['getHome'] : '/home'
        }
    },
    methods: {
        // USER ACTIONS
        userConfig() {
            this.closeBox()
            this.$router.push({
                name: 'user'
            })
        },

        userLogout(event) {
            event.preventDefault()
            event.stopPropagation()

            var self = this
            const employee_id = this.$store.getters['loginUser/getLocalEmployee']

            if (employee_id) {
                self.$store
                    .dispatch('loginUser/doLogout', {
                        employee: employee_id
                    })
                    .then(function (response) {
                        self.closeBox()
                        self.$router.push(self.$root.redirectPath(self.home)).catch((err) => {
                            if (err.name == 'NavigationDuplicated') {
                                self.$router.push('/reset' + self.redirectPath(self.home))
                            }
                        })
                    })
            }
        },

        // GENERAL METHODS
        closeBox() {
            this.$store.commit('setMenuUserVisible', false)
        },

        redirectPath(firstResult) {
            // console.log('redirect')
            return this.$root.redirectPath(firstResult)
        },

        toCurrentRoute() {
            let currentPath = this.$route.path
            let aux = this.$route.path.split('/')[1]
            let parentPath = typeof aux !== 'undefined' ? '/' + aux : false

            if (parentPath && parentPath != currentPath) {
                this.$router.push({ path: parentPath })
            }
        }
    }
}
</script>

// PORTRAIT STYLES
<style lang="scss">
#user-box.overlay.portrait {
    $menu-top-height: 60px;
    background-color: transparent;
    z-index: 19000000;
    height: calc(100% - #{$menu-top-height});
    height: 100%;
    width: 100vw;
    position: absolute;
    top: $menu-top-height;
    top: 0;
    right: 0;

    .menu {
        width: 90vw;
        height: calc(100% - #{$menu-top-height} + 1px);
        background-color: $default-bg-color;
        position: absolute;
        right: 0;
        // top: 0;
        top: 59px;
        max-width: 400px;
        z-index: 2000000;
        padding: 15px;

        .user-info {
            @include display-flex();
            @include align-items();
            height: 90px;
            margin-bottom: 10px;
        }

        .user-actions {
            height: calc(100% - 100px);
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-gap: 15px;
            align-items: start;
            align-content: start;
            justify-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 0px;
            padding-bottom: 30px;
            overflow: auto;

            .action {
                @include border-radius(5px);
                @include display-flex();
                @include align-items();
                $action-height: 50px;
                background-color: #fff;
                width: 100%;
                height: $action-height;
                padding: 10px;
                cursor: pointer;

                .icon {
                    width: 40px;
                    height: 100%;
                    // @include background($position: left center, $size: 30px);
                    // background-position: center center;
                    // background-size: 30px;
                }

                .text {
                    width: calc(100% - 40px);
                    line-height: calc(#{$action-height} - 10px * 2);
                    font-family: $text;
                }

                &.profile {
                    .icon {
                        @include background($image: img('user_neutro_s70.svg'), $position: left 5px center, $size: 20px);
                    }
                }
                &.logout {
                    .icon {
                        @include background($image: img('power_off_neutro_s70.svg'), $position: left 5px center, $size: 20px);
                    }
                }
            }
        }
    }
}
</style>
