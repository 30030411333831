<template>
    <div id="firstUserSiteWizard" class="hide-scrollbar" :class="[$mq]">
        <template v-if="welcome">
            <div class="welcome-container">
                <div class="form-container">
                    <div class="title">
                        <vue-typed-js :strings="[$t('user_wizard.title')]" :loop="false" :typeSpeed="2" :startDelay="0" :showCursor="false">
                            <span class="titl typing"></span>
                        </vue-typed-js>
                    </div>
                    <div class="description" v-if="location" v-html="$t('user_wizard.description', { location: location.name })"></div>
                    <div class="andy-image">
                        <img src="~@/../public/img/andy_kitchen.png" alt="andy_kitchen" />
                    </div>
                    <!-- <div class="create-btn" @click="next()">{{ $t('login.pw_forgot.action') }}</div> -->

                    <div class="footer-btn">
                        <div class="return" @click="finishedBtn()">{{ $t('menu_aux.action.cancel') }}</div>
                        <div class="next-btn" @click="next()">{{ $t('login.pw_forgot.action') }}</div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="!welcome && !finish">
            <div class="form-container">
                <div class="andy-head">
                    <img src="~@/../public/img/alexhead_06.svg" alt="andy_pasos1" />
                </div>
                <div class="form-content">
                    <div class="titl">
                        {{ singleStep.title }}
                    </div>
                    <div class="description" :class="{ invisible: !singleStep.description }">
                        {{ singleStep.description }}
                    </div>
                    <div class="input" v-if="singleStep.id != 'avatar'">
                        <input @keyup.enter="nextStep()" :placeholder="$t('config.users.name')" v-if="singleStep.id == 'name'" type="text" v-model="user.name" class="input" :class="{ error: errors.name }" />
                        <input @keyup.enter="nextStep()" :placeholder="$t('config.users.surname')" v-if="singleStep.id == 'surname'" type="text" v-model="user.surname" class="input" :class="{ error: errors.surname }" />
                        <input @keyup.enter="nextStep()" min="0" v-if="singleStep.id == 'pin'" type="number" :placeholder="$t('config.users.pin')" oninput="validity.valid||(value='');" @input="onlyNumbers($event)" @change="onlyNumbers($event)" :class="{ error: errors.pin }" v-model="user.pin" class="password" />
                    </div>
                    <div class="extra-description" v-if="singleStep.extraDescription">
                        {{ singleStep.extraDescription }}
                    </div>
                    <div class="avatar-container" v-if="singleStep.id == 'avatar'">
                        <div class="available-avatar">
                            <div class="img-container scrollbar">
                                <img class="avatar-img" v-for="image in images" :key="image.pathLong" v-bind:src="image.pathLong" :class="{ active: image.pathShort.slice(2) == user.avatar }" @click="setAvatar(image)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-btn">
                    <div class="return" @click="returnStep()">{{ $t('menu_aux.action.back') }}</div>
                    <div class="next-btn" @click="nextStep()">
                        {{ $t('login.pw_forgot.action') }}
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="welcome-container">
                <div class="description" v-html="$t('user_wizard.finished')"></div>
                <div class="title">
                    <vue-typed-js :strings="[$t('user_wizard.finish')]" :loop="false" :typeSpeed="2" :startDelay="0" :showCursor="false">
                        <span class="titl typing"></span>
                    </vue-typed-js>
                </div>
                <div class="andy-image">
                    <img src="~@/../public/img/andy_kitchen.png" alt="andy_kitchen" />
                </div>
                <div class="create-btn-center" @click="finishedBtn()">{{ $t('login.actions.login') }}</div>
            </div>
        </template>
    </div>
</template>

<script>
import avatarSelector from '@/components/domain/user/AvatarSelector'
export default {
    name: 'FirstUserSiteWizard',
    components: {
        avatarSelector
    },
    data() {
        return {
            user: {
                name: '',
                surname: '',
                pin: '',
                avatar: ''
            },
            images: [],
            errors: {
                name: false,
                surname: false,
                pin: false
            },
            initial_step: 0,
            steps: [
                {
                    id: 'name',
                    title: this.$t('user_wizard.title_name'),
                    description: this.$t('user_wizard.description_name'),
                    extraDescription: this.$t('user_wizard.extra_description_name')
                },
                {
                    id: 'surname',
                    title: this.$t('user_wizard.title_surname')
                },
                {
                    id: 'pin',
                    title: this.$t('user_wizard.title_pin'),
                    description: this.$t('user_wizard.description_pin'),
                    extraDescription: this.$t('user_wizard.extra_description_pin')
                },
                {
                    id: 'avatar',
                    title: this.$t('user_wizard.title_avatar')
                }
            ],
            welcome: true,
            finish: false
        }
    },
    computed: {
        location() {
            return this.$store.getters['login/getLocation']
        },
        singleStep() {
            return this.steps[this.initial_step]
        }
    },
    methods: {
        importAll(r) {
            r.keys().forEach(key => this.images.push({ pathLong: r(key), pathShort: key }))
        },
        setAvatar(image) {
            var img = image.pathShort.slice(2)
            this.user.avatar = img
        },
        onlyNumbers(e) {
            if (this.user.pin.toString().length > 4 || this.user.pin.toString().length < 0) {
                this.user.pin = this.user.pin.toString().slice(0, 4)
            }
        },
        next() {
            this.welcome = false
        },
        nextStep() {
            if (this.initial_step == 0 && this.user.name.length == 0) {
                this.errors.name = true
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('config.users.name_required') })
            } else if (this.initial_step == 2 && this.user.pin.length <= 3) {
                this.errors.pin = true
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('config.users.pin_required') })
            } else if (this.initial_step == 3 && this.user.avatar == '') {
                this.errors.avatar = true
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('config.users.avatar_required') })
            } else if (this.initial_step == 3) {
                var self = this
                if (fnCheckConnection()) {
                    var allRoles = this.$store.getters['login/getRoles']
                    var roleLevel3 = false
                    for (var role in allRoles) {
                        if (allRoles[role].level == 3) {
                            roleLevel3 = allRoles[role]
                        }
                    }
                    this.user.rol = roleLevel3.id
                    this.user.password = this.user.pin
                    this.$overlay.show()
                    this.$store.dispatch('employee/addNewEmployee', this.user).then(function(response) {
                        if (response) {
                            // self.$emit('cancel')
                            // self.$overlay.hide()
                            self.$overlay.hide()
                            self.$snackbar.success({ duration: 3000, closeable: true, message: self.$t('config.users.add_success', { name: self.user.name }) })
                            self.finish = true
                        } else {
                            let errorMessage = typeof response.error !== 'undefined' ? response.error.message : 'common.api.error'
                            self.$overlay.hide()
                            self.$snackbar.error({ duration: 3000, closeable: true, message: self.$t(errorMessage) })
                            self.$emit('finish', 'skip')
                        }
                    })
                } else {
                    // if user is offline, don't create user but let loggin with another user (hide popup)
                    self.$overlay.hide()
                    this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('config.users.no_internet_wizard') })
                    self.$emit('finish', 'skip')
                }
            } else {
                this.initial_step++
            }
        },
        returnStep() {
            this.errors = _.mapValues(this.errors, () => false)
            if (this.initial_step == 0) {
                this.welcome = true
            } else {
                this.initial_step--
            }
        },
        finishedBtn() {
            this.$emit('finish', 'skip')
        }
    },
    mounted() {
        this.importAll(require.context('../../../public/img/avatar/', true, /\.svg$/))
    }
}
</script>

<style lang="scss">
#firstUserSiteWizard {
    width: 100%;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 40px;
    overflow: auto;
    .welcome-container {
        @include display-flex();
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        width: 100%;
        height: 100%;
        position: relative;

        .title {
            .titl {
                @include font-size(ml);
                color: $main-dark;
                font-family: $text-bold;
                text-align: center;
            }
            padding-bottom: 20px;
        }

        .description {
            @include font-size(m);
            color: $inactive-s80;
            font-family: $text;
            line-height: 36px;
            text-align: center;
            padding-bottom: 30px;
            height: 20%;
        }

        .andy-image {
            @include display-flex();
            @include justify-content(center);
            width: 100%;
            height: auto;
            min-height: 150px;
        }

        .create-btn {
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            @include font-size(ml);
            @include border-radius(4px);
            width: fit-content;
            height: 50px;
            padding: 0 20px;
            background-color: $main-dark;
            font-family: $text-bold;
            color: #fff;
            cursor: pointer;
            position: absolute;
            bottom: 15px;
            right: 15px;
        }
        .create-btn-center {
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            @include font-size(ml);
            @include border-radius(4px);
            width: calc(100% - 20px);
            height: 60px;
            max-width: 400px;
            padding: 10px 36px;
            background-color: $main-dark;
            font-family: $text-bold;
            color: #fff;
            cursor: pointer;
            position: absolute;
            bottom: 30px;
        }
        .description {
            @include font-size(m);
            color: $inactive-s80;
            font-family: $text;
            text-align: center;
            padding: 0px 15px;
        }
    }

    .form-container {
        @include display-flex();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(space-between);
        width: 100%;
        padding: 0px 15px;
        height: 100%;
        max-width: 600px;
        margin: 0 auto;
        overflow-y: auto;
        position: relative;
        min-height: 490px;

        .form-content {
            @include display-flex();
            @include flex-direction(column);
            @include align-items(center);
            gap: 10px;
            width: 100%;
            height: calc(100% - 20% - 65px);

            input {
                @include border-radius(4px);
                @include font-size(sm);
                font-family: $text;
                color: $neutro-s70;
                width: 100%;
                max-width: 600px;
                border: none;
                min-height: 40px;
                height: 50px;
                padding: 3px 12px;
                cursor: text;
                top: 50%;

                &::placeholder {
                    @include font-size(sm);
                    color: $neutro-s30;
                    font-family: $text;
                    font-style: normal;
                }
                &.error {
                    border: 1px solid $error;
                }
            }
            .extra-description {
                @include font-size(s);
                color: $inactive-s80;
                font-family: $text;
                text-align: center;
            }
            .input {
                width: 100%;
                height: 50px;
                max-width: 570px;
            }
            .description {
                @include font-size(m);
                color: $inactive-s80;
                font-family: $text;
                text-align: center;
            }
            .titl {
                @include font-size(l);
                color: $main-dark;
                font-family: $text-bold;
                text-align: center;
                line-height: 40px;
                padding: 12px 0px;
            }
            .avatar-container {
                width: 100%;
                max-height: calc(100%);
                overflow: auto;

                .available-avatar {
                    height: 100%;
                    .img-container {
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        .avatar-img {
                            @include interaction();
                            margin: 4px;
                            width: 60px;
                            height: 60px;
                            position: relative;
                            top: 0;
                            opacity: 0.5;

                            &:hover {
                                // top: -3px;
                            }
                            &.active {
                                opacity: 1;
                                animation: move 0.8s ease;
                                animation-iteration-count: infinite;
                                animation-timing-function: linear;
                            }

                            @keyframes move {
                                0% {
                                    transform: scale(1);
                                }
                                50% {
                                    transform: scale(1.15);
                                }
                                100% {
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                }
            }
        }

        .footer-btn {
            @include display-flex();
            @include flex-direction(row);
            @include justify-content(space-between);
            @include align-items(center);
            width: 100%;
            height: 65px;
            padding-bottom: 15px;
            margin-top: auto;

            .return {
                @include font-size(ml);
                @include border-radius(4px);
                @include display-flex();
                @include align-items(center);
                @include justify-content(center);
                width: calc(50% - 5px);
                max-width: 280px;
                height: 50px;
                position: relative;
                padding: 0 20px;
                background-color: $neutro;
                font-family: $text-bold;
                color: $main;
                cursor: pointer;
            }
            .next-btn {
                @include font-size(ml);
                @include border-radius(4px);
                @include display-flex();
                @include align-items(center);
                @include justify-content(center);
                width: calc(50% - 5px);
                max-width: 280px;
                height: 50px;
                position: relative;
                padding: 0 20px;
                background-color: $main-dark;
                font-family: $text-bold;
                color: #fff;
                cursor: pointer;
            }
        }

        .andy-head {
            max-width: 80px;
            height: 20%;
        }
    }
    &.landscape {
        .description {
            @include font-size(l);
        }
        .title {
            @include font-size(xl);
            .titl {
                @include font-size(xl);
            }
        }
    }
    &.desktop {
        @include display-flex();
        @include align-items(center);
        .form-container {
            max-height: 500px;
        }
        .welcome-container {
            justify-content: flex-start;
            max-height: 600px;
        }
        .description {
            @include font-size(l);
        }
        .title {
            @include font-size(xl);
            .titl {
                @include font-size(xl);
            }
        }
    }
}
</style>
