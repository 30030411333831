<template>
    <div id="tools-box" :class="[$mq, 'overlay']" @click.self="closeBox">
        <div class="menu">
            <!-- USER INFO -->
            <div class="location-info">
                <div class="name">{{ locationData.name }}</div>
            </div>
            <!-- USER ACTIONS -->
            <div class="tools hide-scrollbar">
                <!-- HOME TOOL -->
                <div class="tool" :class="{ selected: location.indexOf('home') > -1 }" id="navHome" @click="navTool('/')">
                    <div class="icon"></div>
                    <div class="text">{{ $t('tools.home_title') }}</div>
                </div>

                <!-- ANDY TOOLS -->
                <template v-for="(tool, i) in tools">
                    <div class="tool" :class="{ selected: location.indexOf(tool.action) > -1 }" :id="tool.id" v-if="!tool.type && visibleTools.indexOf(parseInt(tool.code)) != -1" @click="navTool(tool.action)" :key="'main_tools' + i">
                        <div class="icon"></div>
                        <div class="text">{{ $t(tool.name) }}</div>
                    </div>
                </template>

                <!-- EXTERNAL TOOLS -->
                <template v-for="(tool, i) in Object.values(external_tools)">
                    <div class="tool" :class="{ selected: location.indexOf(tool.action) > -1 }" v-if="tool.type && tool.type != 'intent'" @click="navToolExternal(tool)" :key="'external_tools' + i">
                        <div class="icon" :style="{ backgroundImage: 'url(' + iconTool(tool) + ')' }"></div>
                        <div class="text">{{ $t(tool.name) }}</div>
                    </div>
                </template>

                <!-- CONFIG TOOL -->
                <div class="submenu-tool">
                    <div class="tool" :class="{ selected: location.indexOf('config') > -1 }" id="navConfig" @click="navTool('/config/location', 'config')">
                        <div class="icon"></div>
                        <div class="text">{{ $t('menu_tools.config_title') }}</div>
                    </div>
                    <div class="subtools" :class="{ visible: location.indexOf('config') > -1 || submenuExpand.config }">
                        <div class="subtool" @click="navTool('/config/location')" :class="{ selected: $route.path == '/config/location' }">
                            <div class="text">{{ $t('config.location.title') }}</div>
                        </div>
                        <div class="subtool" @click="navTool('/config/printer')" :class="{ selected: $route.path == '/config/printer' }">
                            <div class="text">{{ $t('config.printer.title') }}</div>
                        </div>
                        <div class="subtool" @click="navTool('/config/dashboard')" :class="{ selected: $route.path == '/config/dashboard' }">
                            <div class="text">{{ $t('config.dashboard.title') }}</div>
                        </div>
                        <div class="subtool" @click="navTool('/config/users')" :class="{ selected: $route.path == '/config/users' }">
                            <div class="text">{{ $t('config.users.title') }}</div>
                        </div>
                        <div class="subtool" @click="navTool('/config/status')" :class="{ selected: $route.path == '/config/status' }">
                            <div class="text">{{ $t('config.status.title') }}</div>
                        </div>
                    </div>
                </div>

                <!-- RELEASES TOOL -->
                <div class="tool" v-if="Object.values(releases).length > 0" :class="{ selected: location.indexOf('release') > -1 }" id="navRelease" @click="navTool('/release')">
                    <div class="icon"></div>
                    <div class="text">{{ $t('menu_tools.release_title') }}</div>
                </div>

                <!-- HELP TOOL -->
                <div class="tool" :class="{ selected: location.indexOf('tickets') > -1 }" id="navHelp" @click="navTool('/tickets')">
                    <div class="icon"></div>
                    <div class="text">{{ $t('menu_tools.tickets') }}</div>
                </div>
            </div>

            <div class="version">{{ $t('tooltip.version') }}: {{ appVersion }}</div>
        </div>
    </div>
</template>

<script>
import employee from '@/components/domain/login/employee'

export default {
    components: { employee },
    data() {
        return {
            submenuExpand: { config: false }
        }
    },
    computed: {
        locationData() {
            return this.$store.getters['loginUser/getLocalLocation']
        },

        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },

        releases() {
            return this.$store.getters['login/getAllReleases']()
        },

        // user() {
        //     var employee = this.$store.getters['employee/getEmployee'](this.emp_id)

        //     if (employee) {
        //         return employee
        //     }
        // }
        userRole() {
            var user = this.$store.getters['employee/getEmployee'](this.emp_id)
            var roles = this.$store.getters['employee/getAllRoles']
            if (user && Object.keys(roles).length > 0) {
                return parseInt(roles[user.rol].level)
            }
        },

        location() {
            return this.$route.path
        },

        appVersion() {
            return process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : ''
        },

        // TOOLS COMPUTED
        tools() {
            var tools = this.$store.getters['getToolsByRoleLevel'](this.userRole)
            log(tools)
            var aTools = Object.values(tools)
            aTools.sort(function(t1, t2) {
                // console.log(t1, t2)
                var p1 = typeof t1.priority !== 'undefined' ? t1.priority : 9999
                var p2 = typeof t2.priority !== 'undefined' ? t2.priority : 9999
                return p1 < p2 ? -1 : 1
            })

            var self = this
            self.alexTools = 0
            aTools.forEach(tool => {
                if (typeof tool.type === 'undefined') self.alexTools++
            })

            log(aTools, this.visibleTools)
            return aTools
        },

        external_tools() {
            var tools = this.$store.getters['getToolsByRoleLevel'](this.userRole)
            var aTools = []
            for (var k in tools) {
                var tool = tools[k]
                if (typeof tool.type !== 'undefined') aTools.push(tool)
            }
            aTools.sort(function(t1, t2) {
                // console.log(t1, t2)
                var p1 = typeof t1.priority !== 'undefined' ? t1.priority : 9999
                var p2 = typeof t2.priority !== 'undefined' ? t2.priority : 9999
                return p1 < p2 ? -1 : 1
            })

            return aTools
        },

        visibleTools() {
            var visible = []
            const config = this.$store.getters['login/getConfig']
            if (config && config.tools) {
                config.tools.forEach(tool => {
                    if (![8, 9].includes(tool)) {
                        visible.push(tool)
                    }
                })
            }

            return visible
        }
    },
    methods: {
        // TOOL ACTIONS
        navTool(action, subtool = false) {
            log(!subtool)
            if (subtool) {
                this.submenuExpand[subtool] = !this.submenuExpand[subtool]
            } else {
                let self = this
                if (self.$store.getters['hasAccess'](self.currentEmployee().id, 0) || action == '/config/location' || action == '/release') {
                    // self.$router.push(self.redirectPath(action));

                    self.$router.push(self.redirectPath(action)).catch(err => {
                        if (err.name == 'NavigationDuplicated') {
                            self.$router.push('/reset' + self.redirectPath(action))
                        }
                    })
                } else {
                    this.$popup.login({
                        list: this.$store.getters['employee/getLoginUsers'](0),
                        callAfterAccess: function(response) {
                            if (response) {
                                // self.$router.push(self.redirectPath(action));
                                self.$router.push(self.redirectPath(action)).catch(err => {
                                    if (err.name == 'NavigationDuplicated') {
                                        self.$router.go(self.$router.history.current)
                                    }
                                })
                                self.$popup.close()
                            }
                        }
                    })
                }
            }
        },
        navToolExternal(tool) {
            if (tool.type == 'url') {
                var hyperlink = tool.action.startsWith('www') ? 'https://' + tool.action : tool.action
                window.open(hyperlink, '_blank')
            } else if (this.isAPP) {
                window.plugins.launcher.canLaunch(
                    { packageName: tool.action },
                    function() {
                        window.plugins.launcher.launch(
                            { packageName: tool.action },
                            function() {
                                log('INTENT LANZADO')
                            },
                            function() {}
                        )
                    },
                    function() {}
                )
            } else {
                log('TODO nada para poder hacer mostrar alerta?')
            }
        },

        iconTool(tool) {
            return tool.icon
        },

        // GENERAL METHODS
        closeBox() {
            this.$store.commit('setMenuToolsExpanded', false)
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        redirectPath(firstResult) {
            // console.log('redirect')
            return this.$root.redirectPath(firstResult)
        },

        toCurrentRoute() {
            let currentPath = this.$route.path
            let aux = this.$route.path.split('/')[1]
            let parentPath = typeof aux !== 'undefined' ? '/' + aux : false

            if (parentPath && parentPath != currentPath) {
                this.$router.push({ path: parentPath })
            }
        }
    }
}
</script>

// PORTRAIT STYLES
<style lang="scss">
#tools-box.overlay.portrait {
    $menu-top-height: 60px;

    #navHome {
        .icon {
            background-image: img('menu_home_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_home_light.svg');
            }
        }
    }
    #navTickets {
        .icon {
            background-image: img('menu_support_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_support_light.svg');
            }
        }
    }

    #navRelease {
        .icon {
            background-image: img('menu_release_dark.svg');
        }

        &.selected {
            .icon {
                background-image: img('menu_release_light.svg');
            }
        }
    }

    #navConfig {
        .icon {
            background-image: img('menu_config_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_config_light.svg');
            }
        }
    }
    #navLabel {
        .icon {
            background-image: img('menu_label_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_label_light.svg');
            }
        }
    }
    #navDigitalLabel {
        .icon {
            background-image: img('menu_elabel_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_elabel_light.svg');
            }
        }
    }
    #navLibrary {
        .icon {
            background-image: img('menu_library_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_library_light.svg');
            }
        }
    }
    #navChecklist {
        .icon {
            background-image: img('menu_checklist_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_checklist_light.svg');
            }
        }
    }
    #navRegister {
        .icon {
            background-image: img('menu_register_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_register_light.svg');
            }
        }
    }
    #navAudit {
        .icon {
            background-image: img('menu_audit_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_audit_light.svg');
            }
        }
    }
    #navIssue {
        .icon {
            background-image: img('menu_issue_dark.svg');
        }
        &.selected {
            .icon {
                background-image: img('menu_issue_light.svg');
            }
        }
    }
    #navHelp {
        .icon {
            background-size: 30px;
            background-image: img('menu_help.svg');
        }
        &.selected {
            .icon {
                background-image: img('info_main_light.svg');
                background-size: 25px;
            }
        }
    }

    background-color: transparent;
    z-index: 19000000;
    height: calc(100%);
    width: 100vw;
    position: absolute;
    top: $menu-top-height;
    top: 0;
    left: 0;

    .menu {
        $menu-top-height: 60px;
        width: 90%;
        height: calc(100% - #{$menu-top-height} + 1px);
        background-color: $default-bg-color;
        position: absolute;
        left: 0;
        top: 59px;
        max-width: 400px;
        z-index: 2000000;
        padding: 15px;

        .location-info {
            @include display-flex();
            @include align-items();
            height: 50px;
            margin-bottom: 10px;

            .name {
                @include background($size: 19px, $image: img('location_inactive-s70.svg'), $position: left 8px center);
                @include font-size(xs);
                @include text-ellipsis();
                padding-left: 30px;
                color: $inactive-s70;
                display: inline-block;
                width: fit-content;
                font-family: $text-bold;
                height: rem(30px);
                // line-height: rem(30px);
                line-height: 40px;
                max-width: 100%;
            }
        }

        .tools {
            // display: grid;
            // grid-template-columns: 1fr;
            // grid-template-rows: auto;
            // grid-gap: 15px;
            // align-items: start;
            // align-content: start;
            // justify-items: center;
            // justify-content: center;
            @include display-flex();
            @include align-items();
            @include flex-direction(column);
            width: 100%;
            height: calc(100% - 60px - 30px - 10px);
            padding: 0px;
            // padding-bottom: 30px;
            overflow: auto;
            margin-bottom: 10px;
            max-width: 100%;

            .tool {
                @include border-radius(5px);
                @include display-flex();
                @include align-items();
                $action-height: 50px;
                background-color: #fff;
                width: 100%;
                height: $action-height;
                min-height: $action-height;
                padding: 10px;
                cursor: pointer;
                margin-bottom: 10px;

                .icon {
                    width: 30px;
                    height: 100%;
                    // @include background($position: left center, $size: 30px);
                    background-position: center center;
                    background-size: 30px;
                    background-repeat: no-repeat;
                }

                .text {
                    @include text-ellipsis();
                    width: calc(100% - 30px);
                    line-height: $action-height;
                    font-family: $text-bold;
                    padding-left: 10px;
                    max-width: 100%;
                }

                &.selected {
                    background-color: $main;
                    .text {
                        color: #fff;
                    }
                }

                &.profile {
                    .icon {
                        @include background($image: img('user_cccccc.svg'), $position: left center, $size: 25px);
                    }
                }
                &.logout {
                    .icon {
                        @include background($image: img('logout_main_t20.svg'), $position: left center, $size: 25px);
                    }
                }
            }

            .submenu-tool {
                width: 100%;

                .tool {
                    // margin-bottom: 5px;
                    .text {
                        padding-right: 20px;
                        @include background($image: img('down_caret_dark.svg'), $position: right center, $size: 10px);
                    }
                    &.selected {
                        .text {
                            @include background($image: img('up_caret_light.svg'), $position: right center, $size: 10px);
                        }
                    }
                }

                .subtools {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                    grid-gap: 5px;
                    align-items: start;
                    align-content: start;
                    justify-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 0px;
                    // padding-bottom: 30px;
                    overflow: hidden;
                    max-height: 0;
                    margin-bottom: 0;
                    transition: 0.2s;

                    &.visible {
                        max-height: 100vh;
                        margin-bottom: 10px;
                    }

                    .subtool {
                        @include border-radius(5px);
                        @include display-flex();
                        @include align-items();
                        $action-height: 50px;
                        background-color: rgba(255, 255, 255, 0.6);
                        width: 100%;
                        height: $action-height;
                        padding: 10px;
                        cursor: pointer;

                        .text {
                            padding-left: 15px;
                            color: $neutro-s60 !important;
                            font-family: $text-bold;
                        }

                        &.selected {
                            background-color: $neutro;
                            .text {
                                color: $main !important;
                            }
                        }
                    }
                }
            }
        }

        .version {
            @include display-flex();
            @include align-items(flex-end);
            @include justify-content(flex-end);
            @include font-size(xs);
            color: $neutro-s50;
            width: 100%;
            height: 30px;
            text-align: right;
        }
    }
}
</style>
